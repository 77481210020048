@import '../../Styles/global-imports.scss';

.navBar {
    height: 75px;
    @include transition;

    @include themed() {
        background: t($bg) !important;
    }
}

a.navbar-brand {
    color: white;
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
  @include font-size(14px);
}

body {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
}

header {
    flex-shrink: 0;
    width: 100%;
}

main {
    flex-grow: 1;
    overflow-x: auto;
}

.navbar {
    position: sticky;
    top: 0;
    background-color: #30365d;
    padding: 8px 0px;
}
.Updatebtnstyle{
    display:flex;
    margin:20px 60px 20px 64px;
}

.container-fluid {
    padding: 0 !important;
}

a:link, a:visited, a:hover, a:active {
    text-decoration: none;
}

.dimmed-page {
    filter: brightness(0.4);
    transition: all 0.8s ease 0s;
    pointer-events: none;
}

.nav-link-text {
    width: 100%;
}


@media (min-width: 768px) {
    html {
    @include font-size(16px);
  }
}

.c247logo {
    height: 48px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.sidebar-container {
    /* Refactor as nesting too much */
    li {
        ul {
            li {
                &:hover {
                    border-left: 4px solid #de4767;
                    transition: .1s;
                }

                &:first-child {
                    border-top: 1px solid rgba(255,255,255,0.1);
                }
            }
        }

        &:hover {
            border-left: 4px solid $PinkNormal;
            transition: .1s;
        }

        img {
            /*margin-right: 1em;*/
        }

        padding-left: 32px;
    }

    width: 0;
    transition: width 0.5s ease;
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 75px;
    white-space: nowrap;
    z-index: 63;

    @include themed() {
        background: t($bg);
        color: t($text);
    }
}

.sidebar-header {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include themed() {
        background: t($bg);
        color: t($text);
    }
}

.sidebar-toggle {
    &.open {
        @include transform(translateX(14em));
        @include transition();
        border-bottom-left-radius: 10px !important;
        border-bottom-right-radius: 0px !important;
    }

    @include themed() {
        color: t($text);
    }
    z-index: 64;
}

.burger {
    width: 24px;
    height: 24px;
    display: block;
    margin: auto;
}

.sidebar-container.open {
    width: 350px;

    @include themed() {
        background: t($bg);
        color: t($text);
    }
}

@media only screen and (max-width: 992px) {
    .sidebar-toggle.open {
        left: 30%;
        transform: translateX(-64px);
    }

    .sidebar-container.open {
        width: 30%;
    }
}

@media only screen and (max-width: 481px) {
    .sidebar-toggle.open {
        left: 55%;
        transform: translateX(-64px);
    }
    .sidebar-container.open {
        width: 55%;
    }
}

.sidebar-menu-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 125px);
}

.sidebar-menu {
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @include themed() {
        background: t($bg);
        color: t($text);
    }
}
.go-dark {
    label {
        @include themed() {
            color: t($text);
        }
    }
}
.nav-item {
    .nav-link {
        &:hover {
            color: inherit !important;
            transition: 0.1s !important;
            text-decoration: none !important;
        }

        &:visited {
            color: inherit;
            text-decoration: none;
        }

        @include font-size(16px);
        @include transition(all 0.5s ease);
        text-decoration: none !important;
        padding: 15px 10px 15px 20px !important;
        display: inline-block !important;
        cursor: inherit;

        @include themed() {
            color: t($text);
        }
    }

    display: block;
    width: 100%;
    cursor: pointer;
}



.link-arrow {
    padding: 15px 10px 15px 20px;
    display: inline;
}

.HoldCenter {
    margin: 1rem 6rem 1rem 0rem;
}

.button-open {
    margin-left: 1em;
}