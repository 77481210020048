@import '../../Styles/global-imports.scss';

// Stonegate Branding
$statsBg: #D9D9D9;
$ctaBg: #7F7384;
$orange: #FD5D03;

$tableHeader: #4A4A4A;
$tableBody: rgb(240, 240, 240);

.report-notice {
    padding:15%;
    svg {
        height: 80px;
        width: 80px;
    }
}
.report-header {
    .form-label {
        font-size: 14px;
        color: white;
    }

    .statistics-bar {
        &__item {
            &:last-of-type {
                border: none;
            }

            padding-right: 2em;
            margin-right: 2em;
            border-right: 1px solid #fff;

            @media (max-width: 575.98px) {
                border: none;
                padding: 0.5em 1em;
                margin: 0;
            }
        }

        background: white;
        border-radius: 6px;
        overflow-x: scroll;
    }
}
.stat-header {
    border-bottom: 1px solid $grayLight;
}

.css-1nmdiq5-menu {
    z-index: 9999!important;
}

.stonegate-highlight {
    td {
        background: #00FFFF!important;
    }
}
.stonegate-container {
    background-image: url('../../images/manager-report/stonegate-full-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    height:100vh;
}

.apply-button {
    @include transition;
    @media (max-width: 575.98px) {
        width:100%!important;
    }
}
.clear-button {
    background: none!important;
    color: white!important;
    border: 1px solid white;
    @media (max-width: 575.98px) {
        width: 100% !important;
    }
}
.btn.btn-stonegateCta {
    &:hover {
        background: #3D3740;
        color: white;
    }

    @include transition;
    color: white;
    background: $orange;
    padding: 0.5em 1.2em;
}

.report-filters {
    .dropdown-menu.show {
        max-height: 50vh !important;
    }
    .dropdown {
        min-width: 200px;
    }
    input {
        @media (max-width: 575.98px) {
            margin-bottom: 0.8em;
        }
    }

    @media (max-width: 575.98px) {
        align-items: flex-start !important;
    }
}

.report-controls, .report-filters {
    .checked {
        background: #ddd !important;
    }

    button.dropdown-toggle {
        width: 100%;
        }

        .btn.btn-primary {
            &:hover {
                .radio-circle {
                    border-color: white;
                }

                background: #ddd;
            }

            @include transition;
            background: white;
            border: 1px solid #ced4da;
            border-radius: 6px !important;
            color: #333;
            padding: 0.36em 0.8em;

            @media (max-width: 575.98px) {
                &:last-of-type {
                    margin-bottom: 0;
                }

                width: 90%;
                margin-bottom: 0.8em;
            }
        }

        margin-right: 1em;
    }

.manager-reports-container {


    .dropdown-toggle {
        margin-right: 0;
    }



    .percent {
        font-size: 12px;
    }

    

    .report-table-container {
        .notes {
            min-width: 200px;
            max-width: 400px;
            white-space: pre-wrap;
        }

        table {
            tr {
                &:hover {
                    background-color: #333;
                }
            }

            th {
                background: $tableHeader;
            }

            td {
                background: $tableBody;
            }

            margin-bottom: 0;
        }

        overflow: scroll;
        border-radius: 6px;
        border: 1px solid #ddd;
        height: 90%;
        padding-bottom: 1em;
    }

    height: 72.5vh;
    border-radius: 6px;
}